@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@400;900&family=Nunito:wght@300;400;500;800&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

body{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    color: $white;
    overflow-x: hidden;
    background: $black;
}
.quiz{
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;

    &.quiz-intro {
        background-image: url(../styles/img/main-img-bg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 !important;
        padding: 0 !important;
    }

    &.quiz-sub {
        background-color: $white;
    }
}
.logo{
    position: absolute;
    top: 30px;
    left: 40px;
}
.escapebox{
    outline: none;
    border: none;
    cursor: pointer;
    margin: 0 auto 20px auto;
    
}
.container{
    width: 100%;
    max-width: 700px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden !important;
    margin: 0 auto !important;
    padding: 0 !important;
}
.quiz-container{
    max-width: 700px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.h1-welcome{
    font-family: 'Londrina Solid', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 43px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    width: 100%;
}
.p-main{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    width: 100%;
}
.welcome-card-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row !important;
    width: 100%;
}

.welcome-icon-1, .welcome-icon-2, .welcome-icon-3{
    padding: 20px;
}
.btn-secondary{
    width: 250px;
    height: 50px;
    background: $theme;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $white;
    text-transform: uppercase;
    border: none;
    animation: heartBeat 2s infinite;
}
@keyframes heartBeat{
    0%{
        transform: scale(1.035);
    }
    50%{
        transform: scale(1.075);
    }
    100%{
        transform: scale(1.035);
    }
}

.btn-secondary:hover{
    background-color: #77c1bf;
}
.questionMenu{
    height: 100%;
    width: 100vw;
    color: $darkgray;
    background: $white;
    flex-direction: column;
}
.question-text{
    font-family: 'Londrina Solid', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $darkgray !important;
    max-width: 600px;
    margin: 0 auto;
}
.question-description{
    max-width: 475px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $darkgray;

}
.btn-question{
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    transition: .4s;


    &.imageText-answer{
        width: 125px;
        height: 160px;
        background-color: $theme;
        color: $white;
        font-size: 18px !important;
        border: none;
        border-radius: 9px;
        margin: 0 7px;
        text-align: center;
    }

    &.text-answer{
        width: 425px;
        height: 40px;
        background-color: $theme;
        color: $white;
        font-size: 18px !important;
        border: none;
        border-radius: 9px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column !important;
    }
}
.btn-question:hover{
    background-color: $button;
    transform: scale(1.020) !important;
}
.btn{
    padding: 0 !important;
}
.product-results{
    width: 100vw;
    background-color: $theme;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 85px;
}
.product-recommendation-title{
    font-family: 'Londrina Solid', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    width: 100%;
}
.card{
    border-radius: 15px;
    background: $white;
    transition: .4s;
    box-shadow: 0px 5px 5px hsla(0, 0%, 0%, 0.25);
    padding: 10px;
    cursor: pointer;
    max-width: 275px;
    margin: 0px 12px 35px 12px;
}
.card:hover{
    transform: scale(1.020);
}
ul{
    padding-left: 0 !important;
}
.btn-back{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: $darkgray;
    opacity: 0.85;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    margin-top: 60px;
    margin-bottom: 30px;
    position: absolute;
    left: 0;
    right: 0;
}
.bi-arrow-left{
    font-size: 20px;
    position: relative;
    top: 2px;
}
.btn-primary{
    width: 205px;
    height: 40px;
    background: $theme;
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border: none;
    outline: none;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-primary{
    background-color: $theme !important;
    transition: .4s;
}
.btn-primary:hover{
    background-color: #6ca9a7 !important;
    outline: none !important;
    border: none !important;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }
.card-title{
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: $black;
}
.card-title.h5{
    margin-bottom: 14px;
}
.card-text, .checkmark{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $black;
    margin: 0 auto !important;
}
p.card-text{
    padding-bottom: 10px;
}
.checkmark{
    list-style-image: url(../styles/svg/li-checkmark.svg);
    line-height: 20px;
    margin: 0 auto;
    padding-left: 10px;
    width: 185px;
}
.restart-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bi-arrow-counterclockwise{
    font-size: 16px;
    color: $black;
}
.btn-restart-quiz{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $black;
    outline: none;
    border: none;
    transition: .4s;
}
.btn-restart-quiz:hover{
    color: $black;
    transform: scale(1.020);
}
.results{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30vw;
    text-align: center;
}
.selected-option{
    list-style-image: url(../styles/svg/selected-option.svg);
    font-style: normal;
    font-size: 16px;
    color: $black;
}
.medal{
    height: 38px;
    width: 38px;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
}
.row{
    height: 100%;
    background: $theme;
    --bs-gutter-x: 0 !important;
}
.chosen-answers-wrapper{
    background: rgba(203, 237, 236, 0.67);
    border-radius: 6px;
    max-width: 1100px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 24px;
}
.chosen-answers{
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $black;
    list-style-type: none;
}
.bi-check-circle-fill{
    color: $black;
}

.welcome-card-p{
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $white;
    width: 175px;
}
/* STEP PROGRESS BAR */
#stepProgressBar{
    position: absolute;
    top: 17%;
}
.progress-bar-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 140px;
    margin: 0 auto;
    text-align: center;
    position: relative;
}
.progress-bar-wrapper::after{
    content: " ";
    position: absolute;
    width: 100%;
    height: .7px;
    background: #85D0CE;
    bottom: 5.75px;
    z-index: 1;
}
.stepBullet{
    width: 12.65px;
    height: 12.65px;
    border-radius: 100%;
    list-style: none;
    padding-left: 0;
    z-index: 2;
    background: #D9D9D9;
}
.stepBullet.done{
    background: #85D0CE;
}
.stepBullet.active{
    background: #208380;
    box-shadow: 0px 0px 8px 3px rgba(32, 131, 128, 0.4);    
}
/*Mobile Responsive*/
@media screen and (max-width: 428px) {
    .logo{
        position: absolute;
        top: 15px;
        left: 25px;
    }
    .h1-welcome{
        width: 85%;
        font-size: 28px;
        padding: 0 25px;
    }
    .p-main{
        width: 85%;
        font-size: 16px;
        padding: 0 25px;
    }
    .icon{
        width: 35px;
        height: 35px;
    }
    .welcome-card-p{
        font-size: 12px;
        width: 85px;
    }
    .btn-secondary{
        width: 200px;
        height: 42px;
        font-size: 16px !important;
    }
    .question-text{
        font-size: 24px;
    }
    .btn-back{
        position: static;
        top: 40px;
    }
    .product-recommendation-title{
        font-size: 28px;
        padding: 20px 35px 0px 35px;
    }
    .btn-question{ 
        &.text-answer{
            width: 300px;
        }
    }
    .btn-question:hover{
        background-color: $button;
        transform: scale(1.020);
    }
    .chosen-answers-wrapper{
        display: none;
    }
    .product-results{
        padding-top: 75px;
        margin-bottom: 200px;
    }
    .m-mobile{
        margin-bottom: 50px;
    }
    .btn-success{
        margin-left: 0 !important;
    }
    .welcome-card-1, .welcome-card-2, .welcome-card-3{
        padding: 15px;
    }
    .img22{
        width: 100%;
    }
    .img26{
        width: 100%;
        padding-top: 50px;
    }
    .escapebox{
        margin: 0 auto 10px auto;
    }
    .preloader-wrapper{
        width: 90%;
    }
    .quiz-container{
        margin-top: 35px;
    }
    .question-description{
        font-size: 14px;
    }
    .btn-question{
   
        &.imageText-answer{
            width: 100px;
            height: 120px;
            font-size: 16px !important;
        }
        &.text-answer{
            font-size: 16px !important;

        }
    }
    .btn-question img {
        width: 67%;
    }
    .btn-question:hover{
        background-color: $theme;
        transform: scale(1.020) !important;
    }
    .quiz-content{
        margin-top: 100px !important;
    }
    .question-text{
        margin-top: 40px;
    }
}
.product-landing-wrapper{
    max-width: 1100px;
    height: 100%;
    margin: 0 auto;
}
h4{
    font-family: 'Londrina Solid', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $darkgray;
    max-width: 350px;
    margin: 0 auto;
}

h5{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #4F4F4F;
    max-width: 440px;
    margin: 0 auto;
}
.p-landing{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #4F4F4F;
    max-width: 440px;
    margin: 0 auto;
}
.btn-success{
    width: 200px;
    height: 50px;
    background-color: #85D0CE !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .4s;
    margin-left: 50px;
    
}
.btn-success:hover{
    background-color: #6ca9a7 !important;
    transform: scale(1.025);
}
.btn-success:focus{
    outline: none;
    border: none;
    box-shadow: none !important;
}

.p-grid{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.grid-wrapper{
    max-width: 1100px;
}
/* Recommended products preloader */
.preloader-container{
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.preloader-wrapper{
    width: 90%;
    max-width: 675px;
    height: 400px;
    background-color: $theme;
    border-radius: 15px;
    flex-direction: column;
}
.h3-loader{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: $black;
    width: 400px;
}
.loading-animation{
    width: 225px;
    height: 145px;
    background-color: transparent;
    border-radius: 10px;
}
.loading{
    width: 125px;
    height: 125px;
}
small{
    font-style: italic;
    font-weight: 700;
    font-size: 12px;
    color: $black;
}
.escapebox-btn-wrapper{
    max-width: 500px;
    height: 50px;
    margin: 0 auto;
}
.quiz-content{
    margin-top: 0px;
}